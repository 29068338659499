//--------------------------------------------------
//Quest Page
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import { Sources } from '../QuestComponents/Sources'
import { Message } from '../QuestComponents/Message'
import { Travelogue } from '../QuestComponents/Travelogue';
import { Ordering } from '../QuestComponents/OrderingPhase';
import { Matching } from '../QuestComponents/MatchingPhase';
import { Helmet } from "react-helmet"

const Quest = ({ session }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const quest = params.get("quest");
    const token = localStorage.getItem('token');

    document.title = 'Quest ' + quest;

    const [content, setContent] = useState({ content: {} });
    const [currentPhase, setCurrentPhase] = useState({})
    const [currentPhasePos, setCurrentPhasePos] = useState(-1);
    const [displayMessage, setDisplayMessage] = useState('The Quest Message will display here.');
    const [currentSource, setCurrentSources] = useState(null);
    const [imgPos, setImgPos] = useState(0);

    const [goToUnity, setGoToUnity] = useState(false);

    const [displayPhaseButton, setPhaseButton] = useState('hidden');

    const [answers, setAnswers] = useState({})
    const [progress, setProgress] = useState({})

    const [state, setState] = useState(Date.now())
    
    useEffect(() => {
        userActions.GetQuestContent(quest, session.token, (data) => {
            const { err } = data;
            if (err) {
                window.alert('This Quest is locked or unavailable.')
                return;
            }
            setContent(data);
            nextPhase(data);
        })
        userActions.GetTravelogue(session.token, quest, (answers, progress) => {
            setAnswers(answers ? answers : {});
            setProgress(progress ? progress : {});
        })
    },
        []);

    const nextPhase = (content) => {
        if (!content.content[Object.keys(content.content)[currentPhasePos + 1]]) {
            return;
        }
        const newPhase = content.content[Object.keys(content.content)[currentPhasePos + 1]];
        setCurrentPhase(newPhase);
        if (newPhase?.category == 'snapshot') {
            let temp = [];
            console.log(content.content)
            for (const x in content.content) {
                if (x == 'phase_1') {
                    continue;
                }
                for (const i in content.content[x].sources) {
                    temp.push(content.content[x].sources[i])
                }
            }
            setCurrentSources(temp);
            console.log(temp)
        }
        else if (newPhase.category == 'unity') {
            setGoToUnity(true)
        }
        else {
            setCurrentSources(newPhase?.sources);
        }
        setDisplayMessage(newPhase?.message);
        setCurrentPhasePos(currentPhasePos + 1);
        togglePhaseButton(false);
    }

    const MovetoNextPhase = (error) => {
        if (error) {
            return;
        }
        let temp = progress;
        temp[currentPhasePos] = 1;
        setProgress(temp);
        nextPhase(content);
        togglePhaseButton(false);
    }

    const handleGoToPhase = (phase, id) => {
        setCurrentPhase(phase);
        if (phase?.category == 'snapshot') {
            let temp = [];
            for (const x in content.content) {
                if (Object.keys(content.content)[0] == x) {
                    continue;
                }
                for (const i in content.content[x].sources) {
                    temp.push(content.content[x].sources[i])
                }
            }
            setCurrentSources(temp);
            console.log(temp)
        }
        else if (phase.category == 'unity') {
            setGoToUnity(true)
        }
        else {
            setCurrentSources(phase?.sources);
        }
        setDisplayMessage(phase?.message);
        setCurrentPhasePos(id);
        if (progress[id] == 1) {
            togglePhaseButton(true);
        }
    }

    const togglePhaseButton = (display) => {
        setPhaseButton(display ? '' : 'hidden');
    }

    const SetSnaphostSource = (source, imgPos) => {
        setImgPos(imgPos);
        let temp = answers;
        temp[currentPhasePos].Source = source;
        setAnswers(temp);
        setState(Date.now());
    }

    const AddTeachMoreSource = (source) => {
        let temp = answers;
        const sources = temp[currentPhasePos].Source.split("   ");
        temp[currentPhasePos].Source = ''
        for (const image in sources) {
            if (sources[image] != source) {
                temp[currentPhasePos].Source += (sources[image] + "   ");
            }
        }
        if (!sources.includes(source)) {
            temp[currentPhasePos].Source += (source + "   ");
        }
        setAnswers(temp);
        setState(Date.now());
    }

    const UpdateTravelogue = () => {
        userActions.UpdateTravelogue(token, quest, { answers: answers, progress: progress }, (data) => {
            
        })
    }

    //console.log(phaseCount)

    return (
        <Fragment>
            <div className={'page'} id='page'>
                <Helmet>
                    <meta
                        http-equiv="Content-Security-Policy"
                        content="frame-src * self blob: data: gap:;"
                    ></meta>
                </Helmet>
                <div className='toGamePopup' style={{ display: goToUnity ? 'grid' : 'none' }}>
                    <div className='popupBody'>
                        <p>The next part of the quest is now within the game.</p>
                        <button className='register-button' onClick={() => { window.location.href = 'game/'; UpdateTravelogue();}}>GO TO GAME</button>
                        <button className='register-button closePopupBtn' onClick={() => { setGoToUnity(false) }}>Back</button>
                    </div>
                </div>
                <div className={'questActivityContainer'}>
                    {currentPhase.category == 'ordering'
                        ?
                        <Ordering content={currentPhase.sources} backgroundImage={currentPhase.background} togglePhaseButton={togglePhaseButton} isFinished={progress[currentPhasePos] == 1 ? true : false} />
                        :
                        currentPhase.category == 'matching'
                            ?
                            <Matching content={currentPhase.sources} backgroundImage={currentPhase.background} togglePhaseButton={togglePhaseButton} isFinished={progress[currentPhasePos] == 1 ? true : false} />
                            :
                            <Sources message={displayMessage} sources={currentSource} togglePhaseButton={togglePhaseButton} backgroundImage={currentPhase.background} type={currentPhase.type} category={currentPhase.category} currentPhasePos={currentPhasePos} AddSnapshotSource={SetSnaphostSource} AddTeachMoreSource={(AddTeachMoreSource)} />
                    }
                    <Travelogue key={state} phases={content.content} currentPhasePos={currentPhasePos} goToPhase={handleGoToPhase} currentPhase={currentPhase} answers={answers} progress={progress} MovetoNextPhase={MovetoNextPhase} displayButton={displayPhaseButton} currentSource={currentSource} imgPos={imgPos} />
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {

};

const connection = connect(mapState, actionCreators)(Quest);
export { connection as Quest };