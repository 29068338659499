//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import placeholder from '../../Images/placeholder.jpg'

const ExhibitList = ({ key }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const student_id = params.get("student");
    const token = localStorage.getItem("token");

    const [content, setContent] = useState(null);
    const [username, setUsername] = useState("");
    const [openQuests, setOpenQuests] = useState([]); //holds whether the quest dropdowns are open or not

    useEffect(() =>
        GetExhibits(),
        []);

    function GetExhibits() {
        userActions.GetStudentExhibits(classroom_id, student_id, token, (data) => {
            setContent(data.exhibits[0].quest_exhibits)
            setUsername(data.student[0].username)
        })
    }

    function displayExhibits(exhibits) {
        //console.log("exhibits: ", exhibits);
        if (!exhibits || Object.keys(exhibits).length == 0) {
            return (
                <div className='studentRow'>
                    <span className='bold font-lg grid-col-12'>This {params.get("student") ? 'student' : 'class'} has not yet completed any phases!</span>
                </div>
            )
        }
        return (
            <div>
                {Object.keys(exhibits).map((questKey) => {
                    return (
                        <div className="Quest">
                            <div className='dropdown' onClick={() => toggleQuest(questKey)}>
                                <h1>
                                    Quest {questKey}
                                </h1>
                                {openQuests.includes(questKey) ? <span class="material-symbols-outlined">keyboard_arrow_down</span> : <span class="material-symbols-outlined">keyboard_arrow_right</span>}
                            </div>

                            {openQuests.includes(questKey) && (
                                <div className="phasesContainer">
                                    <ExhibitImage classroom_id={classroom_id} questKey={questKey} />
                                    {ExhibitAnswers(exhibits[questKey][0].data)}
                                </div>
                            )}


                        </div>
                    )
                })}
            </div>
        )
    }

    function ExhibitImage({ classroom_id, questKey }) {
        const [icon, setIcon] = useState(null);
    
        useEffect(() => {
            userActions.GetExhibitImage(classroom_id, questKey, (data) => {
                setIcon(data.url); 
            });
        }, [classroom_id, questKey]);
    
        return (
            <div>
                <img width={400} height={"auto"} src={icon ? icon : placeholder} alt={"Exhibit"} />
            </div>
        );
    }

    function ExhibitAnswers(answers) {
        const pAnswers = JSON.parse(answers);

        return (
            <div>
                {pAnswers.map((item, index) => {
                    let keyName = key + ":";
                    switch (parseInt(index)) {
                        case 0:
                            keyName = 'What happened?';
                            break;
                        case 1:
                            keyName = 'Who was involved?';
                            break;
                        case 2:
                            keyName = 'Where did it happen?';
                            break;
                        case 3:
                            keyName = 'When did it happen?';
                            break;
                        case 4:
                            keyName = 'Why is this an important part of Idaho history?';
                            break;
                        default:
                            keyName = 'Unknown key';
                    }
                    return (
                        <div className='Phase'>
                            <h3>{keyName}</h3>
                            <p key={index}>{item}</p>
                        </div>
                    )
                })}
            </div>
        )
    }

    // Function to toggle quest dropdown
    const toggleQuest = (questKey) => {
        setOpenQuests((prev) =>
            prev.includes(questKey)
                ? prev.filter((quest) => quest !== questKey)
                : [...prev, questKey]);
    };

    return (
        <Fragment>
            <head>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            </head>
            <div className={'studentView'} key={key}>
                <div className='grid-col-2 headerRow'>
                    <h1 className='Approval-Travelogue-Header'>Exhibits</h1>
                    <h2 className='Approval-Travelogue-Header'>{username}</h2>
                </div>
                <div className='studentContainer' >
                    {displayExhibits(content)}
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ExhibitList);
export { connection as ExhibitList };