//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import placeholder from '../../Images/placeholder.jpg'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import incompletePopsicle from '../../Images/incomplete_popsicle.png'
import completePopsicle from '../../Images/complete_popsicle.png'

const StudentDashboard = ({ }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const student_id = params.get("student");
    const token = localStorage.getItem("token");

    const [content, setContent] = useState(null);
    const [quests, setQuests] = useState(null);
    const [icon, setIcon] = useState(null);

    const [currentQuest, setCurrentQuest] = useState(null);
    const [questProgress, setQuestProgress] = useState({});

    useEffect(() =>
        userActions.GetClassroomContent(classroom_id, token, (data) => {
            setContent(data);
            GetProgress(data.students[0].travelogue);
            if (data.students[0].icon) {
                userActions.GetIcon(data.students[0].id, (data) => {
                    setIcon(data.url);
                });
            }
        }, '?student='+student_id), 
    []);

    const GetProgress = (travelogue) => {
        let temp = {};
        for (const x in travelogue){
            let tally = 0;
            const quest_total = Object.keys(travelogue[x].progress).length;
            for (const i in travelogue[x].progress) {
                if(travelogue[x].progress[i]==1) {
                    tally++;
                }
                else if(travelogue[x].progress[i]==0 && !currentQuest) {
                    setCurrentQuest(x);
                }
            }
            temp[x] = (tally/quest_total);
        }
        setQuestProgress(temp)
    }

    return (
        <Fragment>
            <Container fluid className={'singleStudentView'}>
                <Row className='student-view-header'>
                    <Col sm={2} className='class-name'>
                        <h2>{content?.classroom.name}</h2>
                    </Col>

                    <Col sm={8} className='student-name'>
                        <h1>Student Dashboard</h1>
                    </Col>
                    <Col sm={2} className='quest-dashboard'>
                        <a href={'/classroom/view/quests?classroom=' + classroom_id} target='_blank'><h3>Quest Dashboard</h3></a>
                    </Col>
                </Row>

                <Row className='student-info-row'>
                    <Col sm={12} className='quest-tracker'>
                        <h1>Quest Tracker</h1>
                        <Row className='quest-popsicles justify-content-center'>
                            <Col xs={1} className='quest-popsicle'>
                                1
                                <img src={questProgress['1']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['1']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                2
                                <img src={questProgress['2']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['2']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                3
                                <img src={questProgress['3']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['3']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                4
                                <img src={questProgress['4']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['4']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                5
                                <img src={questProgress['5']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['5']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                6
                                <img src={questProgress['6']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['6']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                7
                                <img src={questProgress['7']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['7']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                8
                                <img src={questProgress['8']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['8']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                9
                                <img src={questProgress['9']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['9']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                10
                                <img src={questProgress['10']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['10']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                11
                                <img src={questProgress['11']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['11']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                12
                                <img src={questProgress['12']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['13']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                13
                                <img src={questProgress['13']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['13']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                14
                                <img src={questProgress['14']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['14']*100) || 0)}%
                            </Col>
                            <Col xs={1} className='quest-popsicle'>
                                15
                                <img src={questProgress['15']*100|| '0' === 100 ? completePopsicle : incompletePopsicle}></img>
                                {Math.round((questProgress['15']*100) || 0)}%
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className='student-info-row'>
                    <Col sm={6} className='student-section student-links'>
                        <div className="student-profile">
                            <img src={icon ? icon : placeholder}></img>
                            <h1 className='username'>{content?.students[0]?.username}</h1>
                        </div>
                        <div className='student-info'>
                            <h2>Student ID</h2>
                            {content?.students[0].student_id ? 
                                <p>#{content?.students[0].student_id}</p> : 
                                <p>No ID available</p>}
                        </div>
                        <div className="student-portfolio">
                        <a href={"/Classroom/View/Travelogue?classroom=" + classroom_id + "&student=" + student_id}>Student Travelogue</a>
                        <a href={"/Classroom/View/Exhibits?classroom=" + classroom_id + "&student=" + student_id}>Student Museum Exhibit</a>
                        </div>

                    </Col>
                    <Col sm={6} className='quest-progress'>
                        <h1>Current Quest Progress</h1>
                        <Row className='justify-content-center'>
                            <Col xs={5} className='current-quest'>
                                <h2>Current Quest: {currentQuest}</h2>
                                {/*
                                <br/>
                                <h3>Quest Name</h3>
                                */}
                            </Col>
                            <Col xs={5}>
                                <CircularProgressbarWithChildren value={questProgress[currentQuest]*100 || 0} text={`${Math.round((questProgress[currentQuest]*100) || 0)}%`} 
                                styles={buildStyles({
                                    textColor: '#F9FAFF',
                                    pathColor: '#3C2454',
                                    trailColor: '#535481',
                                    backgroundColor: '#8688D8'
                                 })}>
                                    <p className='circular-progress-bar'>Complete</p>
                                 </CircularProgressbarWithChildren>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(StudentDashboard);
export { connection as StudentDashboard };