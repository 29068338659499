
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FaArrowsRotate, FaCirclePlus } from "react-icons/fa6";
import { userActions } from '../../Actions';
import { ClassCreationForm } from './ClassCreationForm';

import pdf from '../../Images/IdaGem_Instructional_Doc.pdf'
import placeholder from '../../Images/placeholder.jpg'

const ClassList = ({ session }) => {

    const [showClassForm, setClassForm] = useState('hidden')
    const [classes, setClasses] = useState([]);
    const [className, setClassName] = useState("Classroom Name");
    const [grade, setGrade] = useState(1);
    const [icon, setIcon] = useState(null);
    const [edit, setEdit] = useState(false);
    const [class_id, setClassID] = useState(0);
    const token = localStorage.getItem('token');
    useEffect(() => RefreshClassList(), []);


    const confirmClassCreation = (bool, name, grade, icon) => {
        if (bool) {
            userActions.CreateClassroom(name, grade, icon, token, () => {
                RefreshClassList();
            })
        }
        setClassForm('hidden');
    }

    const confirmClassEdit = (bool, id, name, grade, icon) => {
        if (bool) {
            userActions.EditClassroom(name, class_id, grade, icon, token, () => {
                RefreshClassList();
                RefreshPage();
            })
        }
    }

    const RefreshClassList = () => {
        userActions.GetClassroomList(token, (data) => {
            if (data)
                setClasses(data);
        })
    }

    const RefreshClassroomCode = (id, element) => {
        userActions.RefreshClassroomCode(id, token, (data) => {
            element.innerHTML = data;
        })
    }

    const RefreshPage = () => {
        window.location.reload();
    }

    if (!token || typeof classes !== 'object') {
        return (
            <Fragment>
                <div className={'page'}>
                    <div className={'classlist'}>
                        User Not Signed In
                    </div>
                </div>
            </Fragment>
        )
    }
    else {
        return (
            <Fragment>
                <div className={'classlist'}>
                    <h1 className='sectionHeader grid-col-1'>Classrooms <span className='text-black'>({classes.length})</span></h1>
                    <div className='classContainer'>
                        {classes?.length > 0
                            ? classes?.map(index => {
                                return (
                                    <Fragment>
                                        <div className='classCol'>
                                            <div className='imageContainer'>
                                                <img src={index.icon}></img>
                                            </div>
                                            <div className='classSection'><span className='grid-col-2'>Classname:</span> <span className='bold font-lg'>{index.name}</span>
                                            </div>
                                            <div className='classSection'> <span className='grid-col-2'>Code:</span> <span className='bold font-lg'>{index.code}</span><FaArrowsRotate className={'refresh-arrows'} onClick={(e) => RefreshClassroomCode(index.id, e.target.parentElement.children[1])} />
                                            </div>
                                            <div className='links'>
                                                <div className='bold font-lg linkElement'>
                                                    <a className='classlist-link' href={'/classroom/' + index.id}>Students</a>
                                                </div>
                                                <div className='bold font-lg linkElement'>
                                                    <a className='classlist-link' href={'/classroom/view/verify?classroom=' + index.id}>Approve</a>
                                                </div>
                                                <div className='bold font-lg linkElement'>
                                                    <a className='classlist-link' href={'/classroom/view/quests?classroom=' + index.id}>Quests</a>
                                                </div>
                                                <div className='bold font-lg linkElement'>
                                                    <a className='classlist-link' onClick={()=>{
                                                        if(window.confirm('Are you sure? This will delete the class and all students connected.') == true) {
                                                            userActions.DeleteClassroom(index.id, session.token, ()=>{
                                                            RefreshClassList();
                                                        })
                                                    }
                                                    }} target='_blank' >Delete</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='classEditCol'>
                                            <a className='classlist-link-small' onClick={() => { setEdit(true); setClassName(index.name); setGrade(index.grade); setIcon(index.icon); setClassID(index.id); setClassForm(''); }}>Edit</a>
                                        </div>
                                    </Fragment>
                                )
                            })
                            : <div className='classCol'>
                                <span className='bold font-lg grid-col-12'>Looks like you don't have any Classrooms. Make one below</span>
                            </div>
                        }
                        <div className={'newClass'}>
                            <FaCirclePlus onClick={() => { setClassForm(''); setEdit(false); }} className='addClass' size={42} />
                            <h2 className='addClassTitle'>Create a Classroom</h2>
                        </div>
                    </div>
                    <iframe class='pdf' src={pdf}></iframe>
                </div>
                <ClassCreationForm display={showClassForm} confirmation={confirmClassCreation} editConfirmation={confirmClassEdit} id={class_id} name={className} grade={grade} icon={icon} edit={edit}/>
            </Fragment>
        )
    }
};


function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {

};

const connection = connect(mapState, actionCreators)(ClassList);
export { connection as ClassList };