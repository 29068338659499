//--------------------------------------------------
//Navbar for the application
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Container, Nav, Navbar, NavDropdown, NavLink, Offcanvas } from 'react-bootstrap';
import { userActions } from '../../Actions';
import { FaRegUser } from 'react-icons/fa';
import placeholder from '../../Images/placeholder.jpg'

const NavigationBar = ({ Logout, session }) => {
    const [icon, setIcon] = useState("");
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    const [offcanvasTitle, setOffCanvasTitle] = useState('Menu');
    const [isLoggedIn, setIsLoggedIn] = useState(false); /* used to make sure the dropdown items is always under the dropdown button by changing the classname of the dowpdown button based on if the user is logged in or not */

    const [userData, setUserData] = useState({
        last_name: 'Account',
        first_name: '[Name]',
        username: '[Username]'
    })

    useEffect(() => {
        userActions.GetUserData(session.token, (data) => {
            if (data) {
                setUserData(data);
                setOffCanvasTitle(data.username);
                setIsLoggedIn(true);
            }
        })
    }, [session])

    useEffect(() => {
        userActions.GetUserData(session.token, (data) => {
            if(data.icon) {
                userActions.GetIcon(userData.id, (data) => {
                   setIcon(data.url);
                });
            }
            else {
                setIcon(placeholder);
            }
        })
    })

    /*functions to handle changes in navbar's format when switching from mobile to desktop */
    const handleClose = () => {
        setIsOffcanvasOpen(false);
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768 && isOffcanvasOpen) {
                handleClose();
            }
            else if(window.innerWidth < 768){
                setIsOffcanvasOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isOffcanvasOpen]);

    /* make the title in the mobile navbar just "Menu" when no user is logged in */
    useEffect (() => {
        if (!session.token){
            setOffCanvasTitle("Menu");
            setIsLoggedIn(false);
        }
    }, [offcanvasTitle])

    return (
        <Fragment>
            {['md'].map((expand) => (
                <Navbar key={expand} expand={expand} className="nav-bg purple" sticky='top'>
                    <Container fluid className='purple'>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            backdrop={false}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    {offcanvasTitle}
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            {!isOffcanvasOpen ?
                                <>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-end flex-grow-1 pe-3 purple" style={{ height: '50px' }}>
                                            {/* <Nav.Link href="/unity">Unity</Nav.Link> */}
                                            {
                                                !session.token ?
                                                    <FaRegUser color={'white'} size={'2em'} style={{ alignSelf: 'center' }} />
                                                    :
                                                    <div className='nav-img'>
                                                        <img src={icon}></img>
                                                    </div>
                                            }
                                            <NavDropdown
                                                title={userData.status == 'teacher' ? userData.last_name : userData.username}
                                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                                className={isLoggedIn ? 'nav-dropdown loggedIn-dropdown' : 'nav-dropdown loggedOut-dropdown'} style={{ alignSelf: 'center' }}
                                                drop={'down'}
                                            >
                                                {userData.status == 'teacher' ?
                                                    <>
                                                        <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
                                                        <NavDropdown.Item href="/settings">Settings</NavDropdown.Item>
                                                    </>
                                                    : userData.status == 'student' ?
                                                        <NavDropdown.Item href="game/">Game</NavDropdown.Item>
                                                        : ''
                                                }

                                                <NavDropdown.Divider />
                                                {
                                                    !session.token ?
                                                        <>
                                                            <NavDropdown.Item href="/register">Register</NavDropdown.Item>
                                                            <NavDropdown.Item href="/login">Login</NavDropdown.Item>
                                                        </>
                                                        :
                                                        <>
                                                            <NavDropdown.Item onClick={() => Logout()}>
                                                                Logout
                                                            </NavDropdown.Item>
                                                        </>
                                                }

                                            </NavDropdown>


                                        </Nav>
                                    </Offcanvas.Body>
                                </>
                                :
                                <>
                                    <Offcanvas.Body>
                                        <Nav>
                                            {/* <Nav.Link href="/unity">Unity</Nav.Link> */}
                                            {userData.status == 'teacher' ?
                                                    <>
                                                        <Nav.Link className='nav-word-link' href="/dashboard">Dashboard</Nav.Link>
                                                        <Nav.Link className='nav-word-link' href="/settings">Settings</Nav.Link>
                                                    </>
                                                    : userData.status == 'student' ?
                                                        <Nav.Link className='nav-word-link' href="game/">Game</Nav.Link>
                                                        : ''
                                                }
                                                {
                                                    !session.token ?
                                                        <>
                                                            <Nav.Link className='nav-word-link' href="/register">Register</Nav.Link>
                                                            <Nav.Link className='nav-word-link' href="/login">Login</Nav.Link>
                                                        </>
                                                        :
                                                        <>
                                                            <Nav.Link className='nav-word-link' onClick={() => Logout()}>
                                                                Logout
                                                            </Nav.Link>
                                                        </>
                                                }
                                        </Nav>
                                    </Offcanvas.Body>
                                </>}

                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    Logout: userActions.Logout,
};

const connection = connect(mapState, actionCreators)(NavigationBar);
export { connection as NavigationBar };